<template>
  <div id="app">
    <video
      ref="video"
      class="video-player"
      autoplay
      muted
      loop
      :src=currentVideo
    />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      connected: false,
      currentVideo: null,
      projectorSocker: null,
      videos: {
        0: '/videos/0.mp4',
        1: '/videos/1.mp4',
        2: '/videos/2.mp4',
      },
    };
  },
  methods: {
    setupSocket() {
      this.gallerySocket = new WebSocket(
        `${process.env.VUE_APP_SERVER_ADDRESS}/ws/projector/`,
      );

      this.gallerySocket.onopen = (e) => {
        console.log(e);

        this.connected = true;
      };

      this.gallerySocket.onclose = (e) => {
        console.log(e);

        this.connected = false;

        console.log('Waiting 5s to reconnect');

        setTimeout(() => {
          this.setupSocket();
        }, 5000);
      };

      this.gallerySocket.onmessage = (e) => {
        console.log(e);

        const data = JSON.parse(e.data);
        this.currentVideo = this.videos[data.video_id];
      };
    },
  },
  mounted() {
    this.currentVideo = this.videos['0'];
    this.setupSocket();
  },
};
</script>

<style>
body {
  background: #000;
  cursor: none;
  margin: 0;
}

#app {
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.video-player {
  max-height: 100vh;
  max-width: 100vw;
}
</style>
